import logo from './logo.svg';
import './App.css';
import Lift from './components/Lift'

function App() {
  return (
    <div>
     <Lift />
    </div>
  );
}

export default App;
